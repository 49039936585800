import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { ThemeProvider, CSSReset, Box } from "@chakra-ui/core"
import '../styles/index.scss'
import theme from "./theme"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box maxW="1000px" m="0 auto" p={5}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>{children}</div>
      </Box>
      <Box as="footer" bg="#F7FAFC" p={8}>
        <Box maxW="1000px" m="0 auto" color="gray.500">
          © {new Date().getFullYear()} <Link to="/">Wheyon</Link>. All Rights Reserved
        </Box>
      </Box>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
