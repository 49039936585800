import React from 'react'
import { theme } from "@chakra-ui/core"

const breakpoints = ["300px", "500px", "1024px", "1440px"];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export default {
  ...theme,
  breakpoints,
  fonts: {
    heading: 'Sarabun, sans-serif',
    body: "Sarabun, sans-serif",
    mono: "Sarabun, sans-serif"
  },
  icons: {
    ...theme.icons,
    menu: {
      path: (
        <path
          fill="currentColor"
          d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
        />
      ),
      viewBox: "0 0 20 20",
    },
  },
};
